import React, { useState, useEffect } from 'react'
import { NotificationContainer } from 'react-notifications'
import Header from './Header'
import Footer from './Footer'
import SideBar from './SideBar'
import SideBarAdmin from './SideBarAdmin'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { appRoutes } from '../../routes/index'
import { connect } from 'react-redux'
import { getUserSession } from '../../utils/user-func'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { isHavePermission } from '../../utils/user-func'
import DoNotHavePermission from '../DoNotHavePermission'
import windowSize from 'react-window-size'
import Maintain from '../Maintain'
import { I18nextProvider } from 'react-i18next'
import i18next from "../../translations/i18n_config"

function App(props) {
  const [isAdminLayout, setIsAdminLayout] = useState(false)
  const [roles, setRoles] = useState(['customer'])
  const [classMainAdmin, setClassMainAdmin] = useState('admin-wrapper')
  const [classMainCustomer, setClassMainCustomer] = useState('goong-wrapper')
  const [classFooterCustomer, setClassFooterCustomer] = useState('goong-footer')
  const [isMaintain, setIsMaintain] = useState(false);

  useEffect(() => {
    if (typeof props.isLoggedIn === 'undefined') {
      props.dispatch(isUserLoggedIn(!!getUserSession()))
    }
    const _roles = getUserSession() && getUserSession()['roles'] ? getUserSession()['roles'] : ['customer']
    setRoles(_roles)

    const pathname = props.location.pathname
    if (pathname === "/maintain") {
      setIsMaintain(true);
      return
    }
    for (let i = 0; i < appRoutes.length; i++) {
      const route = appRoutes[i]
      if (route.path === pathname) {
        setIsAdminLayout(route.layout === 'admin')
        break
      }
      else {
        setIsAdminLayout(false)
      }
    }
  }, [props, isAdminLayout])

  useEffect(() => {
    const user = getUserSession()
    if (user) {
      document.body.classList.add('goong-body')
    } else {
      document.body.classList.remove('goong-body')
    }
  }, [props.location])

  // init Account Kit SDK
  // useEffect(() => {
  //   window.AccountKit_OnInteractive = function () {
  //     window.AccountKit.init({
  //       appId: `${process.env.REACT_APP_FACEBOOK_ACCOUNT_KIT_APP_ID}`,
  //       version: process.env.REACT_APP_FACEBOOK_ACCOUNT_KIT_VERSION,
  //       fbAppEventsEnabled: true,
  //       state: '898989'
  //       // redirect: "localhost:3000",
  //       // debug: true
  //     })
  //   }
  //     ; (function (d, s, id) {
  //       var js,
  //         fjs = d.getElementsByTagName(s)[0]
  //       if (d.getElementById(id)) {
  //         return
  //       }
  //       js = d.createElement(s)
  //       js.id = id
  //       js.src = 'https://sdk.accountkit.com/en_US/sdk.js'
  //       fjs.parentNode.insertBefore(js, fjs)
  //     })(document, 'script', 'accountkit-jssdk')
  // }, [])

  useEffect(() => {
    if (props.windowWidth > 1200 || props.windowWidth < 651) {
      setClassMainCustomer('goong-wrapper')
      setClassFooterCustomer('goong-footer')
    }
  }, [props.windowWidth])

  const onStateWidth = stateW => {
    if (stateW || 'mobile') {
      setClassMainAdmin('admin-wrapper-setWidth')
    }
    if (stateW === false && props.windowWidth > 900) {
      setClassMainAdmin('admin-wrapper')
    }
  }

  const onWidthCustomer = st => {
    if (st === false && props.windowWidth > 650) {
      setClassMainCustomer('goong-wrapper-setWidth')
      setClassFooterCustomer('goong-footer-setWidth')
    }
    if (st && props.windowWidth > 650) {
      setClassMainCustomer('goong-wrapper')
      setClassFooterCustomer('goong-footer')
    }
  }

  return (
    // <React.Fragment>
    //   <I18nextProvider i18n={i18next}>
    //     {
    //       isMaintain ? <Maintain /> :
    //         <>
    //           {
    //             isAdminLayout ? (
    //               <SideBarAdmin stateWidth={onStateWidth} />
    //             ) : (
    //               <>
    //                 <SideBar stateWidth={onWidthCustomer} />
    //                 {props.windowWidth >= 1200 && <Header />}

    //               </>
    //             )
    //           }
    //           <main className={isAdminLayout ? classMainAdmin : classMainCustomer} id={'layout-set'}>
    //             <Switch>
    //               {appRoutes.map(({ name, exact, path, component: Component, isProtected, onlyAllow }) => {
    //                 return (
    //                   <Route
    //                     key={name}
    //                     exact={exact}
    //                     path={path}
    //                     // component={Component}
    //                     render={routeProps => {
    //                       if (isProtected) {
    //                         if (!props.isLoggedIn) {
    //                           return <Redirect to={{ pathname: '/login' }} />
    //                         }
    //                         if (isHavePermission(roles, onlyAllow)) {
    //                           return <Component {...routeProps} />
    //                         }
    //                         else return <DoNotHavePermission />
    //                       } else {
    //                         if (props.isLoggedIn && name === 'Login') {
    //                           return <Redirect to={{ pathname: '/' }} />
    //                         }
    //                         return <Component {...routeProps} />
    //                       }
    //                     }}
    //                   />
    //                 )
    //               })}
    //             </Switch>
    //           </main>
    //           {/* <NotificationContainer />
    //           {!isAdminLayout &&
    //             (props.isLoggedIn || (props.isLoggedIn === false) ? (
    //               <Footer classFooter={classFooterCustomer} />
    //             ) : (
    //               ''
    //             ))
    //           } */}
    //         </>
    //     }
    //   </I18nextProvider>
    // </React.Fragment>
    <React.Fragment>
      <I18nextProvider i18n={i18next}>
        {isMaintain ? (
          <Maintain />
        ) : (
          <>
            {props.location.pathname !== '/login' &&
              props.location.pathname !== '/register' &&
              props.location.pathname !== '/forgot-password' && (
                <>
                  {isAdminLayout ? (
                    <SideBarAdmin stateWidth={onStateWidth} />
                  ) : (
                    <>
                      {props.windowWidth < 1200 && <SideBar stateWidth={onWidthCustomer} />}
                      {props.windowWidth >= 1200 && <Header />}
                    </>
                  )}
                </>
              )}
            <main className={isAdminLayout ? classMainAdmin : classMainCustomer} id={'layout-set'}>
              <Switch>
                {appRoutes.map(({ name, exact, path, component: Component, isProtected, onlyAllow }) => {
                  return (
                    <Route
                      key={name}
                      exact={exact}
                      path={path}
                      render={routeProps => {
                        if (isProtected) {
                          if (!props.isLoggedIn) {
                            return <Redirect to={{ pathname: '/login' }} />
                          }
                          if (isHavePermission(roles, onlyAllow)) {
                            return <Component {...routeProps} />
                          } else {
                            return <DoNotHavePermission />
                          }
                        } else {
                          if (props.isLoggedIn && name === 'Login') {
                            return <Redirect to={{ pathname: '/' }} />
                          }
                          return <Component {...routeProps} />
                        }
                      }}
                    />
                  )
                })}
              </Switch>
            </main>
          </>
        )}
      </I18nextProvider>
    </React.Fragment>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn || !!getUserSession()
  }
}
export default windowSize(withRouter(connect(mapStateToProps)(App)))
