import React, { useState, useEffect } from 'react'
import { isUserLoggedIn } from '../../redux/actions/user-action'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { isAdmin, removeUserSession } from '../../utils/user-func'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_en } from '../../constants_en'
import { SIDE_BAR_MOBILE_LINK as SIDE_BAR_MOBILE_LINK_vi } from '../../constants_vi'
import 'antd/dist/antd.css'
import avatar from '../../assets/images/avatar.png'
import img_goong_logo_2x from '../../assets/images/goong-logo-2x.png'
import img_goong_logo_sb from '../../assets/images/goong-logo-mini.png'
import { Menu, Layout, Divider } from 'antd'
import { getUserSession, getLanguage } from '../../utils/user-func'
import windowSize from 'react-window-size'
import { showAvatar } from '../../utils/common'
import {
  UserSwitchOutlined,
  ContactsOutlined,
  LogoutOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  TableOutlined,
  LoginOutlined
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import ApiService from '../../api-services/request-services'

const { Sider } = Layout

function SideBar(props) {
  const user = getUserSession()
  const [collapsed, setCollapsed] = useState(false)
  const [collapsedMobile, setCollapsedMoblie] = useState(true)

  const { t, i18n } = useTranslation('common')
  const SIDE_BAR_MOBILE_LINK = i18n.language === 'vi' ? SIDE_BAR_MOBILE_LINK_vi : SIDE_BAR_MOBILE_LINK_en
  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem('language', lang)
    ApiService.defaults.headers.common['Accept-Language'] = lang
  }

  useEffect(() => {
    if (props.windowWidth < 1200) {
      props.stateWidth(collapsed)
    }
    if (props.windowWidth < 1201) {
      setCollapsed(true)
    }
  }, [props.windowWidth, collapsed])

  const handleClickBacdrop = () => {
    if (props.windowWidth < 1201) {
      setCollapsedMoblie(true)
    }
  }

  const handleClickLogOut = () => {
    removeUserSession()
    props.dispatch(isUserLoggedIn(false))
    setCollapsed(true)
    setCollapsedMoblie(true)
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    setCollapsedMoblie(!collapsedMobile)
    if (props.windowWidth > 1200) {
      setCollapsedMoblie(true)
    }
  }

  return (
    <div>
      {props.isLoggedIn ? (
        <Layout style={{ minHeight: '100vh' }} className="sidebar-customer-mobile">
          <Sider
            collapsible
            collapsed={props.windowWidth < 1201 ? collapsedMobile : collapsed}
            onCollapse={toggleCollapsed}
            // width={280}
            collapsedWidth={50}
            theme="light"
          >
            <Menu
              defaultSelectedKeys={props.location.pathname === '/' ? '/dashboard' : props.location.pathname}
              mode="inline"
              theme="light"
              className="sidebar-customer-content"
              id="sb-content"
            >
              <Link to={'/'}>
                {(props.windowWidth > 1200 && collapsed) || (props.windowWidth < 1201 && collapsedMobile) ? (
                  <img src={img_goong_logo_sb} alt="goong-logo" className="icon-goong-sb-am" />
                ) : (
                  <img src={img_goong_logo_2x} alt="goong-logo" className="icon-goong-2x" />
                )}
              </Link>
              {(collapsed === false || collapsedMobile === false) && <Divider />}
              <Link to={'/settings/profile'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                <img
                  src={user ? showAvatar(user?.avatar) : avatar}
                  alt={'avatar'}
                  className={
                    (props.windowWidth > 1200 && collapsed) || (props.windowWidth < 1201 && collapsedMobile)
                      ? 'avatar-sidebar-collapsed'
                      : 'avatar-sidebar-customer'
                  }
                />
                {(props.windowWidth > 1200 && collapsed) || (props.windowWidth < 1201 && collapsedMobile) ? (
                  ''
                ) : (
                  <span className="shortNameUserSideBarCustomer">
                    {user?.first_name || '' + ' ' + user?.last_name || ''}
                  </span>
                )}
              </Link>

              {SIDE_BAR_MOBILE_LINK.map(item => {
                return (
                  <Menu.Item key={item.path}>
                    <Link to={item.path} className="menu-mobile__link" onClick={handleClickBacdrop}>
                      {item.icon}
                      <span>{item.text}</span>
                    </Link>
                  </Menu.Item>
                )
              })}

              <Menu.Item key="faqs">
                <Link to={'/faqs'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                  <ContactsOutlined />
                  <span>Faqs</span>
                </Link>
              </Menu.Item>
              {isAdmin() && (
                <Menu.Item key="administration">
                  <Link to={'/administrator'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <UserSwitchOutlined />
                    <span>Administrator</span>
                  </Link>
                </Menu.Item>
              )}

              <Menu.SubMenu
                key="language"
                title={t("language")}
                icon={<GlobalOutlined />}
                style={{ fontWeight: '500' }}
              >
                <Menu.Item onClick={() => changeLanguage("vi")}>Tiếng Việt</Menu.Item>
                <Menu.Item onClick={() => changeLanguage("en")}>English</Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="logOut">
                <button className="menu-mobile__link" onClick={handleClickLogOut}>
                  <LogoutOutlined />
                  <span>{t('log_out')}</span>
                </button>
              </Menu.Item>
            </Menu>
          </Sider>
        </Layout>
      ) : (
        <React.Fragment>
          <Layout style={{ minHeight: '100vh' }} className="sidebar-customer-mobile">
            <Sider
              collapsible
              collapsed={props.windowWidth < 1201 ? collapsedMobile : collapsed}
              onCollapse={toggleCollapsed}
              width={280}
              collapsedWidth={50}
              theme="light"
            >
              <Menu
                defaultSelectedKeys={['4']}
                mode="inline"
                theme="light"
                className="sidebar-customer-content"
                id="sb-content"
              >
                <Link to={'/'}>
                  {(props.windowWidth > 1200 && collapsed) || (props.windowWidth < 1201 && collapsedMobile) ? (
                    <img src={img_goong_logo_sb} alt="goong-logo" className="icon-goong-sb-am" />
                  ) : (
                    <img src={img_goong_logo_2x} alt="goong-logo" className="icon-goong-2x" />
                  )}
                </Link>
                {(collapsed === false || collapsedMobile === false) && <Divider />}
                <Menu.Item key="0">
                  <a href="https://goong.io/" className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <GlobalOutlined />
                    <span>{t("services")}</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="1">
                  <a href="https://goong.io/mobile-apps" className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <AppstoreOutlined />
                    <span>{t("mobile_apps")}</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="2">
                  <a href="https://maps.goong.io/" className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <TableOutlined />
                    <span>{t("live_map")}</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="3">
                  <a href="https://www.goong.io/documents" className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <ProfileOutlined />
                    <span>{t("documents")}</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={'/login'} className="menu-mobile__link" onClick={handleClickBacdrop}>
                    <LoginOutlined />
                    <span>{t("signin")}</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </Sider>
          </Layout>
        </React.Fragment>
      )}
    </div>
  )
}

const mapStateToProps = function (state) {
  return {
    isLoggedIn: state.userAction.isLoggedIn
  }
}

export default windowSize(withRouter(connect(mapStateToProps)(SideBar)))
